export default function (etapa) {
  let window = 'prudutividade.lancarFinanceiro'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Lançar Pagamento de Produtividade no Financeiro',
    width: '500',
    height: '300',
    minHeight: '300px',
    backdrop: false,
    move: true,
    clickOutside: true,
    windowClass: 'erp-window-modern v2',
    contentClass: '',
    props: {
      router: this.$router
    }
  }, () => import('./LancarFinanceiro.vue'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        updated: (wid, data) => {
          console.log('Simple Person created event')
        },
        reloadProdutividade: (wid, data) => {
          console.log('Reload Event')
        }
      })
    }) // return wid
}
