<template>
  <div class="produtividade-page wrapper">
    <div class="panel-resumo" v-if="stats && !disableStats">
      <ul>
        <h6>Resumo dos processos</h6>
        <li v-for="(s, key) in statsFiltered" :key="'s-' + key">
          <div class="c1" :style="{backgroundColor: s.cor + ' !important'}"></div>
          <div class="c2">{{ s.nome }}</div>
          <div class="c3">{{ s.total }}</div>
          <div class="c4"><span v-if="s.tipo !== 'pendentes'">{{ s.pagos }} pagos</span></div>
          <div class="c5"><span v-if="s.tipo !== 'pendentes'">{{ s.naoPagos }} não pagos</span></div>
        </li>
        <!--        <li>
                  <div class="c1 i-pendentes"></div>&lt;!&ndash; END c1 &ndash;&gt;
                  <div class="c2">Pendentes de indicação</div>&lt;!&ndash; END c2 &ndash;&gt;
                  <div class="c3">0</div>&lt;!&ndash; END c2 &ndash;&gt;
                  <div class="c4"></div>&lt;!&ndash; END c4 &ndash;&gt;
                  <div class="c5"></div>&lt;!&ndash; END c5 &ndash;&gt;
                </li>&lt;!&ndash;&ndash;&gt;
                <li>
                  <div class="c1 i-indicados"></div>&lt;!&ndash; END c1 &ndash;&gt;
                  <div class="c2">Indicados</div>&lt;!&ndash; END c2 &ndash;&gt;
                  <div class="c3">0</div>&lt;!&ndash; END c2 &ndash;&gt;
                  <div class="c4">0 pagos</div>&lt;!&ndash; END c4 &ndash;&gt;
                  <div class="c5">0 não pagos</div>&lt;!&ndash; END c5 &ndash;&gt;
                </li>&lt;!&ndash;&ndash;&gt;
                <li>
                  <div class="c1 i-nomeados"></div>&lt;!&ndash; END c1 &ndash;&gt;
                  <div class="c2">Nomeados</div>&lt;!&ndash; END c2 &ndash;&gt;
                  <div class="c3">0</div>&lt;!&ndash; END c2 &ndash;&gt;
                  <div class="c4">0 pagos</div>&lt;!&ndash; END c4 &ndash;&gt;
                  <div class="c5">0 não pagos</div>&lt;!&ndash; END c5 &ndash;&gt;
                </li>&lt;!&ndash;&ndash;&gt;
                <li>
                  <div class="c1 i-homologados"></div>&lt;!&ndash; END c1 &ndash;&gt;
                  <div class="c2">Homologados</div>&lt;!&ndash; END c2 &ndash;&gt;
                  <div class="c3">0</div>&lt;!&ndash; END c2 &ndash;&gt;
                  <div class="c4">0 pagos</div>&lt;!&ndash; END c4 &ndash;&gt;
                  <div class="c5">0 não pagos</div>&lt;!&ndash; END c5 &ndash;&gt;
                </li>--><!---->
      </ul>
      <UBtn @click="exportar" color="primary" icon="file-spreadsheet" icon-type="fa" size="md" no-caps class="m-t" label="Exportar Relatório"></UBtn>
    </div><!-- END Resumo -->
    <div class="panel-resumo" v-if="loadingStats && !disableStats">
      <sl-loading loading-style="coffe"/>
      Preparando as estatísticas para você...
    </div>

    <div v-if="!disableStats" class="cont-search">
      <div class="flex justify-between">
        <div>
          <h6>Pesquisar produtividade pelo número de Processo</h6>
          <div class="cont-form">
            <u-input v-model="search" @keydown.enter="procurarProcesso" outlined></u-input>
            <UBtn @click="procurarProcesso" label="Pesquisar" :disable="!isValidSearch"></UBtn>
          </div>
        </div>
        <div>
          <h6>&nbsp;</h6>
          <UBtn @click="processarFinanceiro" :loading="processandoFinanceiro" label="Processar Lançamento no Financeiro" no-caps class="text-grey" color="white"></UBtn>
        </div>
      </div>
    </div><!-- END Search -->

    <div class="show-cards" v-if="produtividade && !loadingByProcesso">
      <card
          v-for="etapa in produtividade.etapas"
          :key="etapa.id"
          :etapa="etapa"
          :produtividade="produtividade"
          :processo="processo"
          @updateCard="(data) => updateCard(data, etapa)"
          @reloadProdutividade="reloadProdutividade"
      />
    </div>
    <div class="show-cards" v-if="loadingByProcesso">
      <sl-loading content=""/>
    </div>
  </div>
</template>

<script>
import {UInput, UBtn} from 'uloc-vue'
import {
  exportarProdutividade,
  find,
  findByProcesso,
  // processarProdutividadeFinanceiro,
  stats
} from "@/domain/processos/services/produtividade"
import SlLoading from "@/components/layout/components/Loading"
import Card from "@/components/processos/components/produtividade/Card"
import {donwloadFile} from "@/utils/downloadFile"
import lancarFinanceiroWindow from "components/processos/components/produtividade/lancarFinanceiroWindow"

export default {
  name: "Produtividade",
  meta: {
    // title: 'Produtividade'
  },
  props: {
    disableStats: {
      type: Boolean,
      default: false
    },
    processo: {
      default: null
    },
    fullComponent: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Card,
    SlLoading,
    UInput,
    UBtn
  },
  data() {
    return {
      search: null,
      loading: true,
      loadingStats: true,
      loadingByProcesso: false,
      stats: null,
      produtividade: null,
      processandoFinanceiro: false
    }
  },
  computed: {
    statsFiltered () {
      if (!this.stats) return null
      return this.stats.filter(s => s.codigo !== 'intimacoes')
    },
    isValidSearch () {
      return this.search?.trim()
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      // stats
      this.loadingStats = true
      !this.disableStats && stats()
          .then(response => {
            this.loadingStats = false
            this.stats = response.data
            console.log(response.data)
          })
          .catch(error => {
            this.loadingStats = false
            this.alertApiError(error)
          })
      if (this.processo) {
        this.$uloc.loading.show()
        findByProcesso(this.processo)
            .then(response => {
              this.produtividade = response.data
              this.$uloc.loading.hide()
              this.search = this.$options.filters.formataNumeroProcesso(this.produtividade.processo.numero)
            })
            .catch(error => {
              this.alertApiError(error)
              this.$uloc.loading.hide()
            })
      } else if (this.$route.query.id) {
        this.$uloc.loading.show()
        find(this.$route.query.id)
            .then(response => {
              this.produtividade = response.data
              this.$uloc.loading.hide()
              this.search = this.$options.filters.formataNumeroProcesso(this.produtividade.processo.numero)
            })
            .catch(error => {
              this.alertApiError(error)
              this.$uloc.loading.hide()
            })
      }
    },
    procurarProcesso () {
      this.$uloc.loading.show()
      const search = this.search?.replace(/\D/g, '')
      findByProcesso(search)
          .then(response => {
            this.produtividade = response.data
            this.$uloc.loading.hide()
            this.search = this.$options.filters.formataNumeroProcesso(this.produtividade.processo.numero)
          })
          .catch(error => {
            this.alertApiError(error)
            this.$uloc.loading.hide()
          })
    },
    reloadProdutividade(data) {
      console.log('Reload Produtividade Final Event')
      this.load()
    },
    updateCard(data, etapa) {
      console.log('Update', data)
      Object.assign(etapa, data)

    },
    exportar () {
      this.$uloc.loading.show({message: 'Gerando relatório'})
      exportarProdutividade(null, 'excel')
          .then(response => {
            donwloadFile(response)
            this.$uloc.loading.hide()
          })
          .catch(error => {
            this.alertApiError(error)
            this.$uloc.loading.hide()
          })
    },
    lancarFinanceiroWindow,
    processarFinanceiro () {
      this.lancarFinanceiroWindow()
    }
  }
}
</script>
